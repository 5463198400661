* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  }
html,body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}

body{
  margin:0;
  padding:0;
}
